module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"static/favicons/GBS_favicon_64.ico","icons":[{"src":"/favicons/GBS_favicon_16.ico","sizes":"16x16","type":"image/x-icon"},{"src":"/favicons/GBS_favicon_32.ico","sizes":"32x32","type":"image/x-icon"},{"src":"/favicons/GBS_favicon_64.ico","sizes":"64x64","type":"image/x-icon"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"ce93053690eb3deb94a6bc973022f74b"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
